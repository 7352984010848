import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  Grid,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import { likeButtonLogo } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
interface Video {
  attributes: {
    url: string;
    name: string;
  };
}

// Customizable Area End

import YoutubeintegrationController, {
  Props,
  configJSON,
} from "./YoutubeintegrationController";

export default class Youtubeintegration extends YoutubeintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={YoutubeIntegrationStyle.mainWrapper}>
          <Grid container >
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Box sx={YoutubeIntegrationStyle.inputStyle}>
              <Input
                type='text'
                data-test-id = "txtInput"
                placeholder={configJSON.searchButtonPlaceholder}
                fullWidth={true}
                disableUnderline={false}
                value={this.state.txtInputValue}
                onChange={(event) => this.setInputValue(event.target.value)}
              />
              <Box
                data-test-id="btnAddExample"
                onClick={() => this.getYoutubeSearchData()}
                component="button"
                sx={YoutubeIntegrationStyle.buttonStyle}
              >
                <Button color={"primary"}>{configJSON.btnExampleTitle}</Button>
              </Box>
            </Box>
            {this.state.videos.length == 0 && <>
              <Box sx={YoutubeIntegrationStyle.mainVideo}>
                <div >
                  {configJSON.noVideoAvailable}
                </div>
              </Box>
            </>
            }
            {this.state.selectedVideo &&
              <>
                <Grid>
                  <Box sx={YoutubeIntegrationStyle.mainVideo}>
                    <iframe src={`${configJSON.iFrameUrl}${this.state.videoSrc}`} allowFullScreen title={configJSON.iframeYoutubeTitle} />
                    <div >
                      <a href={`${configJSON.youtubeUrl}${this.state.videoSrc}`} target='_blank'>
                        <img src={likeButtonLogo} style={YoutubeIntegrationStyle.likeButton} />
                      </a>
                    </div>
                  </Box>
                </Grid>
              </>
            }

            {this.state.videos &&
              <Grid >
                <Box sx={YoutubeIntegrationStyle.listItem}>
                  {this.state.videos.map((video: Video ) => {
                    return (
                      <>
                        <div>
                          <button
                          data-test-id = "videoSelectButton"
                          onClick={() => this.handleVideoSelect(video.attributes.url)}
                          >
                            {video.attributes.name}
                          </button>
                        </div>
                      </>
                    )
                  })}
                </Box>
              </Grid>
            }
          </Grid>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const YoutubeIntegrationStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "10px",
    background: "#fff",
  },
  mainVideo: {
    marginTop: "50px",
    marginLeft: "20px",
  },
  likeButton: {
    height: "26px",
    marginTop: "8px",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  listItem: {
    overflow: "auto",
    padding: "10px",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: "25px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
// Customizable Area End
