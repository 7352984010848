import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Menu,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    button: {
      textTransform: 'none'
    }
  },
});
// Customizable Area End

import ZipcodessearchController, {
  Props,
  ZipCodeData,
  configJSON,
} from "./ZipcodessearchController";

export default class Zipcodessearch extends ZipcodessearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Grid container xs={12} justifyContent="space-between">
              <Grid item xs={5}>
                <InputLabel htmlFor="zip-code-search" style={webStyle.labelText}>Zip Code</InputLabel>
                <Input
                  id="zip-code-search"
                  data-test-id="zip-code-search"
                  placeholder={configJSON.zipCodePlaceholder}
                  value={this.state.zipCodeSearch}
                  style={webStyle.underlineStyle}
                  disableUnderline={true}
                  onChange={(event) => this.setZipCodeSearchField(event.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <InputLabel style={webStyle.labelText} htmlFor="country-code-search">Country Code</InputLabel>
                <Input
                  id="country-code-search"
                  data-test-id="country-code-search"
                  placeholder={configJSON.countryCodePlaceholder}
                  value={this.state.countryCodeSearch}
                  style={webStyle.underlineStyle}
                  disableUnderline={true}
                  onChange={(event) => this.setCountryCodeSearchField(event.target.value)}
                />
              </Grid>
              <Grid style={webStyle.buttonContainer} container justifyContent="center">
                <Button
                  onClick={() => this.getZipCodesSearchData()}
                  style={webStyle.buttonStyle}
                  variant="contained"
                  data-test-id="search-button-id"
                  color={"primary"}>Search
                </Button>
              </Grid>
              <Menu open={this.state.openMenu} data-test-id="menu-id" onClose={() => this.closeMenu()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {this.state.zipCodeSearchData.length ?
                  this.state.zipCodeSearchData.map((element: ZipCodeData) => {
                    return (
                      <MenuItem data-test-id="menu-item-id" style={webStyle.menuItemStyle} onClick={() => this.setFieldData(element)} key={element.zipCodeId}>{element.postalCode}, {element.postalLocation}, {element.district}, {element.province}, {element.state}, {element.country} </MenuItem>
                    )
                  }) : <MenuItem>Loading...</MenuItem>}
              </Menu>
            </Grid>
            {!this.state.showFormFields ?
              <Grid container style={webStyle.buttonContainer} direction="column" alignItems="center" justifyContent="center">
                <Typography variant="h6">OR</Typography>
                <Button
                  onClick={() => this.setShowFormFields()}
                  style={webStyle.buttonStyle}
                  variant="contained"
                  data-test-id="enter-manully-button-id"
                  color={"primary"}>Enter Manually
                </Button>
              </Grid>
              :
              <Grid container xs={12} justifyContent="space-between">
                <Grid item xs={5}>
                  <InputLabel htmlFor="city" style={webStyle.labelText}>City</InputLabel>
                  <Input
                    id="city"
                    data-test-id="city-test-id"
                    placeholder={configJSON.cityPlaceholder}
                    value={this.state.city}
                    style={webStyle.underlineStyle}
                    disableUnderline={true}
                    onChange={(event) => this.setCityField(event.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel style={webStyle.labelText} htmlFor="state">State</InputLabel>
                  <Input
                    id="state"
                    data-test-id="state-test-id"
                    placeholder={configJSON.statePlaceholder}
                    value={this.state.state}
                    style={webStyle.underlineStyle}
                    disableUnderline={true}
                    onChange={(event) => this.setFieldState(event.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel htmlFor="district" style={webStyle.labelText}>District</InputLabel>
                  <Input
                    id="district"
                    data-test-id="district-test-id"
                    placeholder={configJSON.districtPlaceholder}
                    value={this.state.district}
                    style={webStyle.underlineStyle}
                    disableUnderline={true}
                    onChange={(event) => this.setDistrictField(event.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel style={webStyle.labelText} htmlFor="postal-location">Postal Location</InputLabel>
                  <Input
                    id="postal-location"
                    data-test-id="postal-test-id"
                    placeholder={configJSON.postalLocationPlaceholder}
                    value={this.state.postalLocation}
                    style={webStyle.underlineStyle}
                    disableUnderline={true}
                    onChange={(event) => this.setPostalLocationField(event.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel htmlFor="zip-code" style={webStyle.labelText}>Zip Code</InputLabel>
                  <Input
                    id="zip-code"
                    data-test-id="zip-test-id"
                    placeholder={configJSON.zipCodePlaceholder}
                    value={this.state.postalCode}
                    style={webStyle.underlineStyle}
                    disableUnderline={true}
                    onChange={(event) => this.setPostalCodeField(event.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel style={webStyle.labelText} htmlFor="country-code">Country Code</InputLabel>
                  <Input
                    id="country-code"
                    data-test-id="country-test-id"
                    placeholder={configJSON.countryCodePlaceholder}
                    value={this.state.countryCode}
                    style={webStyle.underlineStyle}
                    disableUnderline={true}
                    onChange={(event) => this.setCountryCodeField(event.target.value)}
                  />
                </Grid>
                <Grid style={webStyle.buttonContainer} container justifyContent="center">
                  <Button
                    onClick={() => this.postZipCodesData()}
                    style={webStyle.buttonStyle}
                    variant="contained"
                    data-test-id="save-button-id"
                    color={"primary"}>Save
                  </Button>
                </Grid>
              </Grid>}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    margin: "20px 0 ",
    width: '100%',
    display: "flex",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "50%",
    height: "45px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  underlineStyle: {
    border: "1px solid #8C8787",
    paddingLeft: "5px"
  },
  labelText: {
    color: "#000",
    margin: "10px 0"
  },
  menuItemStyle: {
    border: "1px solid #8C8787",
  },
  buttonContainer: {
    marginTop: "15px"
  }
};
// Customizable Area End
