Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "youtubeintegration";
exports.labelBodyText = "youtubeintegration Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiUrl = "bx_block_youtubeintegration2/search?search=";
exports.iFrameUrl = "https://www.youtube.com/embed/"
exports.youtubeUrl = "https://www.youtube.com/watch?v="
exports.getApiMethodType= "GET";
exports.errorHeader = "Error";
exports.searchErrorBody = "search field must not be empty";
exports.emptyListMessage = "YouTube videos list not found";
exports.noVideoAvailable = "No video to show Please Search"
// Customizable Area End