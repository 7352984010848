Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postApiMethodType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Zip Code Search";
exports.labelBodyText = "zipcodessearch Body";
exports.zipCodesSearchEndpoint="bx_block_zipcodessearch/zipcodes_search"
exports.btnExampleTitle = "CLICK ME";
exports.zipCodePlaceholder= "e.g. 201301"
exports.countryCodePlaceholder= "e.g. IN"
exports.statePlaceholder= "e.g. Uttar Pradesh"
exports.cityPlaceholder= "e.g. Noida"
exports.districtPlaceholder= "e.g. Gautam Buddha Nagar"
exports.postalLocationPlaceholder= "e.g. Noida H.O"

// Customizable Area End