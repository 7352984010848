import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface ZipCodeData {
  zipCodeId: string,
  country: string,
  postalCode: string,
  postalLocation: string,
  state: string,
  district: string,
  province: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  zipCodeSearch: string;
  countryCodeSearch: string;
  postalCode: string;
  countryCode: string;
  postalLocation: string;
  city: string;
  district: string;
  state: string;
  zipCodeSearchData: ZipCodeData[];
  openMenu: boolean
  showFormFields: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ZipcodessearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getZipCodesSearchId: string
  postZipCodesDataId: string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.getZipCodesSearchId = ''
    this.postZipCodesDataId = ''
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      zipCodeSearch: "",
      countryCodeSearch: "",
      postalCode: '',
      countryCode: "",
      postalLocation: '',
      city: "",
      district: "",
      state: "",
      zipCodeSearchData: [],
      openMenu: false,
      showFormFields: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorJson = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    if (errorJson) this.parseApiCatchErrorResponse(errorJson)
    else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson)
      this.setState({openMenu: false})
    }
    else if (apiRequestCallId == this.getZipCodesSearchId)
      this.setState({ zipCodeSearchData: responseJson })
    else if (apiRequestCallId == this.postZipCodesDataId) {
      this.showAlert('Success', 'Data saved successfully')
      this.clearState()
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };


  // Customizable Area Start
  clearState = () => {
    this.setState({
      txtSavedValue: "A",
      enableField: false,
      zipCodeSearch: "",
      countryCodeSearch: "",
      postalCode: '',
      countryCode: "",
      postalLocation: '',
      city: "",
      district: "",
      state: "",
      zipCodeSearchData: [],
      openMenu: false,
      showFormFields: false,
    })
  }
  setZipCodeSearchField = (value: string) => {
    if (!isNaN(Number(value))) {
      this.setState({ zipCodeSearch: value })
    }
  }

  setCountryCodeSearchField = (value: string) => {
    this.setState({ countryCodeSearch: value })
  }

  setPostalCodeField = (value: string) => {
    if (!isNaN(Number(value))) {
      this.setState({ postalCode: value })
    }
  }

  setPostalLocationField = (value: string) => {
    this.setState({ postalLocation: value })
  }

  setCountryCodeField = (value: string) => {
    this.setState({ countryCode: value })
  }

  setFieldState = (value: string) => {
    this.setState({ state: value })
  }

  setShowFormFields = () => {
    this.setState({ showFormFields: true })
  }
  setFieldData = (element: ZipCodeData) => {
    this.setFieldState(element.state)
    this.setCityField(element.province)
    this.setCountryCodeField(element.country)
    this.setPostalCodeField(element.postalCode)
    this.setPostalLocationField(element.postalLocation)
    this.setDistrictField(element.district)
    this.setShowFormFields()
    this.closeMenu()
  }
  setCityField = (value: string) => {
    this.setState({ city: value })
  }
  setDistrictField = (value: string) => {
    this.setState({ district: value })
  }

  closeMenu = () => {
    this.setState({ openMenu: false, zipCodeSearchData: [] })
  }
  getZipCodesSearchData() {
    if (!this.state.countryCodeSearch || !this.state.zipCodeSearch) {
      this.showAlert('Error', 'Zip code and country code are required fields')
      return
    }
    this.setState({ openMenu: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getZipCodesSearchId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.zipCodesSearchEndpoint}/search_by_zipcode?zip_code=${this.state.zipCodeSearch}&country_code=${this.state.countryCodeSearch}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postZipCodesData() {
    if (!this.state.state || !this.state.postalCode || !this.state.postalLocation || !this.state.countryCode || !this.state.district || !this.state.city) {
      this.showAlert('Error', 'Required fields are missing')
      return
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const reqBody = {
      "country": this.state.countryCode,
      "state": this.state.state,
      "postalcode": this.state.postalCode,
      "postallocation": this.state.postalLocation,
      "district": this.state.district,
      "provience": this.state.city
    }
    this.postZipCodesDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.zipCodesSearchEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}