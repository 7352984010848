import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  Video: {
    attributes: {
      name: string;
      url: string;
    };
  }
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  videoSrc: string;
  videoLink: string;
  videos: Array<{
    attributes: {
      url: string;
      name: string;
    };
  }>;
  gridCSS: number,
  selectedVideo: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class YoutubeintegrationController extends BlockComponent<
  Props,
  S,
  SS>{
  // Customizable Area Start
  getYoutubeDataId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      videoSrc: '',
      videoLink: '',
      videos: [],
      gridCSS: 12,
      selectedVideo: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorJson = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    if (errorJson) this.parseApiCatchErrorResponse(errorJson)

    else if (responseJson.message == configJSON.emptyListMessage) (
      this.parseApiCatchErrorResponse(responseJson.message)
    )
    else if (apiRequestCallId == this.getYoutubeDataId) {
      this.setState({ videos: responseJson.videos.data })
    }
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area Start

  handleVideoSelect = (video: string) => {
    this.setState({ selectedVideo: true, videoSrc: video.split("=")[1] })
  }

  getYoutubeSearchData = () => {
    const headers = {
      search: this.state.txtInputValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getYoutubeDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apiUrl}${this.state.txtInputValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}